@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.service-full-data-div {

    .pdf-download-div {
        display: flex;
        justify-content: space-between;

        .right {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
        }


        button {
            @include normalButton();
            min-width: 100px;
            background-color: rgb(68, 68, 68);
        }
    }

    .section-div {
        border: 1px solid rgb(202, 202, 202);
        padding: 15px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        margin: 15px 0;


        .section-title-div {
            display: flex;
            gap: 7px;
            align-items: center;
            color: rgb(88, 88, 88);
            margin-bottom: 8px;

            h4 {
                font-size: 15px;
            }

        }

        .content-div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;

            .sub-section-div {
                h5 {
                    font-size: 13px;
                    margin-bottom: 5px;
                }

                .texts {
                    display: flex;
                    flex-direction: column;
                    gap: 1px;

                    .line-div {
                        display: flex;
                        justify-content: space-between;
                        gap: 5px;
                        align-items: center;
                        text-align: end;

                        p {
                            font-size: 12px;
                            font-weight: 600;
                            padding: 2px 0;
                        }

                        span {
                            margin: 0;
                        }
                    }

                    .line-head {
                        color: rgb(0, 139, 219);
                    }
                }

                overflow: auto;
                @include scrollBar();

                .table {

                    table {
                        font-size: 12px;
                        font-weight: 600;
                        width: 100%;
                        text-align: center;

                        thead {
                            color: rgb(0, 139, 219);
                        }
                    }
                }
            }
        }

        .table-content-div {

            .table-div {
                width: 100%;

                tbody {
                    font-size: 12px;
                    font-weight: 600;
                    width: 100%;

                    tr {
                        td {
                            text-align: center;
                        }
                    }
                }

            }
        }
    }

    .about-work-div {
        background-color: rgb(230, 246, 255);
    }

    .description-div {
        background-color: rgb(255, 254, 207);
    }

    .assessments-div {
        background-color: rgb(255, 238, 238);
    }

    .spare-changes-div {
        background-color: rgb(230, 255, 225);
        margin: 0px 0;

        .content-div {
            grid-template-columns: 1fr;
        }
    }

    .bill-feed-main-div {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 0 15px;

        .bill-div {
            background-color: rgb(241, 220, 255);

            .content-div {
                grid-template-columns: 1fr 1fr;

                .table {
                    table {

                        td,
                        th {
                            text-align: start;
                        }

                        .total {
                            td {
                                border-top: 1px solid black;
                            }
                        }

                        .grand-total {
                            td {
                                border-top: 1px double black;
                                border-bottom: 1px solid black;
                                background-color: rgb(207, 124, 255);
                            }
                        }
                    }
                }
            }
        }

        .feedback-div {
            .content-div {
                grid-template-columns: 1fr;

                .table {
                    table {

                        td,
                        th {
                            text-align: start;
                        }
                    }
                }
            }
        }
    }






    @media screen and (max-width:800px) {
        .section-div {
            .content-div {
                grid-template-columns: 1fr 1fr;
            }

        }

        .spare-changes-div {
            background-color: rgb(230, 255, 225);

            .content-div {
                grid-template-columns: 1fr;
            }
        }

        .bill-feed-main-div {
            grid-template-columns: 1fr;
            gap: 0px;

        }
    }

    @media screen and (max-width:500px) {
        .section-div {
            .content-div {
                grid-template-columns: 1fr;
            }
        }

        .bill-feed-main-div {
            grid-template-columns: 1fr;
            gap: 0px;

            .bill-div {
                .content-div {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}