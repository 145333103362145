@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.settings-div {
    .section-div {
        margin-bottom: 25px;

        .section-title {
            margin-bottom: 5px;
            color: rgb(160, 160, 160);
        }

        .section-content-div {
            .option-div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgb(226, 226, 226);
                padding: 12px 0px;

                .left {
                    .title {
                        font-size: 13px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 11px;
                        font-weight: 600;
                    }

                    .warning {
                        color: rgb(218, 123, 0);
                    }
                }

                .right {

                    select,
                    input {
                        border: none;
                        font-weight: 600;
                        outline: none;
                        font-size: 13px;
                    }

                    button {
                        @include normalButton();
                        @include iconInButton();
                        font-size: 12px;
                        padding: 8px 20px;
                        background-color: $red_color;
                    }
                }
            }
        }
    }
}