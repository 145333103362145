@import '../../../assets/scss/model.scss';

.service-card-div {
    max-width: 100%;

    .border {
        background-color: rgb(245, 245, 245);
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        @include boxShadow();

        .top {
            display: flex;
            justify-content: space-between;
            padding: 7px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.116);

            .left {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .right {
                display: flex;
                align-items: center;
                gap: 5px;
                text-align: end;
            }
        }

        .content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 7px 10px;
            width: 100%;

            p {
                margin: 5px 0;
            }

            .left {
                p {
                    @include threeDots();
                }
            }

            .right {
                text-align: end;
                text-wrap: nowrap;
            }
        }

        .bottom {
            padding: 7px 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.116);
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                width: 100%;
                @include threeDots()
            }


        }

        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .registered{
        background-color: rgba(119, 250, 174, 0.603);
    }

    .Urgent {
        background-color: rgba(253, 186, 0, 0.322);
    }

    .Emergency {
        background-color: rgba(255, 0, 0, 0.171);
    }


    @media screen and (max-width:600px) {
        .border {
            font-size: 11px;
        }
    }
}