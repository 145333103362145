@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.service-form-section-div {
    .title-section {
        h3 {
            font-weight: 800;
        }

        p {
            font-size: 13px;
            font-weight: 500;
            color: rgb(102, 102, 102);
        }
    }

    .form-div {
        margin-top: 20px;

        .sub-title {
            margin-top: 10px;
            display: flex;
            gap: 8px;
            align-items: center;

            h4 {
                font-size: 14px;
                font-weight: 700;
                display: flex;
                align-items: center;
                gap: 7px;
            }
        }

        .radio-input-border-div {
            .radio-inputs {
                display: flex;
                flex-wrap: wrap;
                gap: 0px 5px;
            }
        }

        .two-input-single-line-div {
            display: flex;
            column-gap: 10px;
            align-items: center;

            .pump-div {
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    font-size: 13px;
                    font-weight: 600;
                    text-align: center;
                }
            }

            >div {
                width: 100%;
            }
        }


        .total-amount-div {
            padding: 25px 10px;

            p {
                font-size: 13px;
                font-weight: 600;
                text-align: center;
            }

            h3 {
                text-align: center;
                color: $primary_color;
            }
        }

    }

    .handle-buttons-div {
        margin-top: 15px;
        display: flex;
        gap: 10px;

        button {
            @include normalButton();
            width: 100%;
        }

        .next {
            background-color: $edit_color;
        }

        .done {
            background-color: $primary_color;
        }

        .submit {
            background-color: $green_color;
            font-size: 17px;
            font-weight: 700;
            padding: 10px 0;
        }
    }



}