@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.success-submit-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);

    .content-border {
        max-width: 400px;
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .section-one-div {
            img {
                width: 130px;
                height: 130px;
            }

            h4 {
                font-size: 14px;
                margin-top: -10px;
            }
        }

        .section-two-div {
            margin-top: 50px;

            .list-item-div {
                display: flex;
                align-items: center;
                gap: 7px;
                margin: 5px 0;

                p {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .section-three-div {
            margin-top: 50px;
            display: flex;
            gap: 15px;

            button {
                @include normalButton();
                width: 110px;

                &:first-child {
                    background-color: $edit_color;
                }
            }
        }
    }
}