@import '../../../assets/scss/color.scss';

.work-category-div {
    .form-div {
        .work-content-div {
            display: flex;
            justify-content: space-evenly;
            gap: 10px;

            .box-div {
                background-color: rgba(101, 203, 221, 0.26);
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 10px;
                position: relative;
                width: 100px;
                cursor: pointer;

                .icon-div {
                    width: 50px;
                    height: 50px;
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    -ms-border-radius: 100px;
                    -o-border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgb(101, 203, 221);

                    svg {
                        font-size: 25px;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }

                .text-div {
                    font-size: 13px;
                    margin-top: 8px;
                    font-weight: 600;
                    text-align: center;
                }

                span {
                    position: absolute;
                    right: 7px;
                    top: 7px;
                    color: rgb(97, 97, 97);
                }
            }

            .selected {
                background-color: rgba(101, 221, 131, 0.26);

                .icon-div {
                    background-color: rgb(101, 221, 131);
                }
            }
        }

        .form-section {
            margin-top: 15px;
        }

        .handle-buttons-div {
            .reset-work {
                background-color: $red_color;
            }
        }
    }
}