@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.add-edit-zone-div {
    position: relative;

    .button-div {
        button {
            @include normalButton();
            width: 100%;
            background-color: $primary_color;
        }
    }

    .loading-pop {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(240, 240, 240, 0.603);
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        backdrop-filter: blur(1px);
    }
}