@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.pending-service-list-div {
    .top-buttons-div {
        display: flex;
        justify-content: space-between;
        gap: 15px;

        .left {
            order: 1;
            display: flex;
            text-align: center;

            .menu-button {
                // display: flex;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 20px;
                cursor: pointer;
                user-select: none;


                span {
                    font-size: 12px;
                    background-color: black;
                    color: white;
                    padding: 0 5px;
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;
                }

                &:hover {
                    background-color: rgb(230, 230, 230);
                }

                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }

            .active {
                border-bottom: 3px solid $edit_color;
                color: $edit_color;
                background-color: rgba(26, 115, 232, 0.082);

                span {
                    background-color: $edit_color;
                }
            }
        }

        .right {
            order: 2;
        }

        button {
            @include normalButton();
            background-color: gray;
            margin-left: 5px;

            span {
                font-size: 12px;
                font-weight: 600;
                background-color: white;
                color: gray;
                padding: 0 5px;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
            }
        }
    }

    .content-div {
        margin-top: 15px;
        .content-border {
            .item-div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
            }
        }
    }


    @media screen and (max-width:750px) {
        .content-div {
            .content-border {
                .item-div {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 15px;
                }
            }
        }
    }

    @media screen and (max-width:600px) {
        .top-buttons-div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;

            .left {
                order: 2;

                .menu-button {

                    font-size: 12px;
                    font-weight: 600;
                    padding: 10px 17px;

                    span {
                        font-size: 11px;
                    }
                }

                .active {
                    border-bottom: 2px solid $edit_color;
                }
            }

            button {
                @include smallButton();
                background-color: gray;
            }

            .right {
                order: 1;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}