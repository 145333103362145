@import '../../../assets/scss/color.scss';

.radio-input-div {
    display: inline-block;

    label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;


        input {
            position: absolute;
            left: -9999px;

            &:checked+span {
                background-color: mix(#fff, $primary-color, 84%);

                &:before {
                    box-shadow: inset 0 0 0 0.4375em $primary-color;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 99em; // or something higher...
            transition: 0.25s ease;
            font-size: 13px;

            &:hover {
                background-color: mix(#fff, $primary-color, 84%);
            }

            &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: #fff;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                margin-right: 0.375em;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em $primary-color;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }
        }

        .checkbox {
            &:before {
                border-radius: 10%;
                -webkit-border-radius: 10%;
                -moz-border-radius: 10%;
                -ms-border-radius: 10%;
                -o-border-radius: 10%;
            }
              border-radius: 0em;
              -webkit-border-radius: 0em;
              -moz-border-radius: 0em;
              -ms-border-radius: 0em;
              -o-border-radius: 0em;
}
    }

}