@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.red-yellow-list-div {

    .buttons-div {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin: 10px 0;

        .button-small-icon {
            @include normalButton();
            font-size: 16px;
            padding: 7px 15px 5px 15px;
            background-color: rgb(53, 53, 53);
        }

        .edit {
            background-color: $edit_color;
        }

        .delete {
            background-color: $red_color;
        }

        .add {
            background-color: rgb(255, 166, 0);
            color: black;
        }

        .action {
            background-color: rgb(0, 112, 116);
            color: white;
        }


    }

}