@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.change-package-div {
    form {
        .buttons-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $primary_color;
            }
        }
    }
}