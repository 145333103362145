.section-seven-div {

    .form-review-div {
        overflow: auto;
        margin-top: 15px;

        >p {
            font-size: 11px;
            font-weight: 600;
            color: gray;
            font-style: italic;
        }

        table {
            width: 100%;
            text-align: center;
            border-collapse: collapse;

            thead {

                th {
                    font-size: 12px;
                    background-color: rgb(53, 53, 53);
                    color: white;
                    padding: 8px 5px;
                }
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                    &:hover {
                        background-color: #ddd;
                    }

                    td {
                        font-size: 12px;
                        font-weight: 600;
                        padding: 8px 5px;

                        &:first-child {
                            text-align: start;
                        }
                    }
                }

                .total {
                    td {
                        border-top: 1px solid black;
                    }
                }

                .grand-total {
                    td {
                        border-top: 2px double black;
                        border-bottom: 1px solid black;
                        background-color: rgb(216, 216, 216);
                    }
                }
            }
        }
    }

    .credit-div {
        margin-top: 20px;

        h5 {
            margin-bottom: 5px;

            span {
                color: red;
                font-size: 16px;
            }
        }
    }

    .signature-div {
        margin-top: 30px;
    }
}