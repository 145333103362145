@import '../../../assets/scss/color.scss';

.reg-full-view-div {
    .content-div {
        display: flex;
        justify-content: space-between;
        gap: 15px;


        .left-div {
            .part-div {
                margin-bottom: 20px;

                h4 {
                    font-size: 15px;
                    color: rgb(85, 85, 85);
                    margin-bottom: 5px;
                }

                h5{
                    color: rgb(0, 0, 0);
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 4px 0;
                    color : rgb(41, 41, 41);
                }
            }
        }

        .right-div {

            .root-div {
                position: relative;
                width: 250px;
                min-width: 250px;
                // background-color: rgb(224, 190, 255);
                padding: 0 5px 10px 25px;
                height: 100px;

                .root-text {
                    h5 {
                        font-size: 13px;
                        font-weight: 600;
                    }

                    p {
                        font-size: 11px;
                        font-weight: 500;
                        color: gray;
                    }
                }

                .dot {
                    width: 10px;
                    height: 10px;
                    background-color: red;
                    border-radius: 100%;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    -ms-border-radius: 100%;
                    -o-border-radius: 100%;
                    position: absolute;
                    left: 7px;
                    top: 4px;
                    z-index: 4;
                }

                .line {
                    width: 2px;
                    height: 100%;
                    background-color: green;
                    position: absolute;
                    left: 11px;
                    top: 8px;
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    -ms-border-radius: 100px;
                    -o-border-radius: 100px;
                    z-index: 3;
                }

            }

            .gray-color {
                .dot {
                    background-color: rgb(49, 49, 49);
                }

                .line {
                    background-color: rgb(49, 49, 49);
                }

                .root-text {
                    h5 {
                        color: rgb(49, 49, 49);
                    }
                }
            }

            .green-color {
                .dot {
                    background-color: $green_color;
                }

                .line {
                    background-color: $green_color;
                }

                .root-text {
                    h5 {
                        color: $green_color;
                    }
                }
            }

            .red-color {
                .dot {
                    background-color: $red_color;
                }

                .line {
                    background-color: $red_color;
                }

                .root-text {
                    h5 {
                        color: $red_color;
                    }
                }
            }

            .scheduled-color {
                .dot {
                    background-color: rgb(99, 2, 163);
                }

                .line {
                    background-color: rgb(99, 2, 163);
                }

                .root-text {
                    h5 {
                        color: rgb(99, 2, 163);
                    }
                }
            }



        }
    }
}