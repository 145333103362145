@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';


.service-form-div {
    position: relative;

    .service-from-border-div {
        display: flex;
        justify-content: center;
        width: 100%;

        .service-form {
            width: 600px;
            max-width: 100%;

            .sticky-nav-div {
                background-color: rgb(5, 122, 107);
                display: flex;
                justify-content: space-between;
                width: 100%;
                position: sticky;
                position: -webkit-sticky;
                top: 0;
                padding: 10px 15px;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
                align-items: center;
                user-select: none;
                z-index: 5;

                .left {
                    display: flex;
                    align-items: center;
                    gap: 10px;


                    .icon-badge-div {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        background-color: rgba(255, 255, 255, 0.822);
                        border-radius: 50px;
                        -webkit-border-radius: 50px;
                        -moz-border-radius: 50px;
                        -ms-border-radius: 50px;
                        -o-border-radius: 50px;
                        padding: 5px 10px;

                        svg {
                            font-size: 18px;
                        }

                        p {
                            font-size: 13px;
                            font-weight: 600;
                            font-family: 'Varela Round', sans-serif;
                        }

                        @media screen and (max-width:400px) {
                            .hide-text {
                                display: none;
                            }
                        }

                    }


                }

                .right {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .before-after-button {
                        display: flex;
                        align-items: center;
                        background-color: rgba(255, 255, 255, 0.822);
                        border-radius: 50px;
                        -webkit-border-radius: 50px;
                        -moz-border-radius: 50px;
                        -ms-border-radius: 50px;
                        -o-border-radius: 50px;
                        padding: 5px 10px;
                        cursor: pointer;

                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }

            .section-div {
                padding-top: 15px;




                .fetch-loading-div {
                    position: fixed;
                    background-color: rgba(236, 236, 236, 0.596);
                    backdrop-filter: blur(1px);
                    width: 100%;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    display: grid;
                    place-content: center;
                    z-index: 7;

                    .box {
                        text-align: center;

                        span {
                            font-size: 50px;
                            color: rgb(85, 85, 85);
                        }

                        p {
                            font-size: 13px;
                            font-weight: 600;
                            color: rgb(85, 85, 85);
                        }
                    }
                }
            }
        }
    }

}