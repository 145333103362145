@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.signCanvas-div {
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    p {
        position: absolute;
        left: 50%;
        top: 45%;
        color: rgba(59, 59, 59, 0.226);
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        user-select: none;
    }

    .signCanvas {
        width: 100%;
        height: 200px;
        background-color: rgb(233, 233, 233);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border: 2px rgb(187, 187, 187) dashed;

    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        button {
            @include normalButton();
            font-size: 12px;
            padding: 7px 15px;
            margin: 0 2px;

            &:first-child {
                background-color: gray;
            }

            &:last-child {
                background-color: $green_color;
            }

        }
    }
}