@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.service-details-div {
    .border-div {
        .section-div {
            margin-top: 15px;

            .section-tit {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 15px;
                color: gray;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .list-div {
                display: grid;
                grid-template-columns: 6fr 8fr;

                .label-div,
                .input-div {
                    padding: 4px 0;
                    display: flex;
                    gap: 5px;

                    p {
                        font-size: 13px;
                        font-weight: 600;
                    }

                    small {
                        font-size: 11px;
                        font-weight: 500;
                        font-style: italic;
                        color: gray;
                    }

                    .copy-icon {
                        color: rgb(0, 122, 204);
                        cursor: pointer;
                    }
                    .call-icon {
                        color: rgb(0, 172, 66);
                        cursor: pointer;
                    }
                }

            }

        }
        .eligibility-div {
    
            .list-div {
               
                .input-div {
                    display: block;
                }
            }
        }
    }



    @media screen and (max-width: 500px) {
        .border-div {
            .section-div {
                .list-div {

                    .label-div,
                    .input-div {
                        p {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}