@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.pending-service-div {
    .service-details-main-div {
        display: flex;
        justify-content: center;

        .page-border-div {
            width: 600px;


            .button-div {
                margin-top: 15px;
                display: flex;
                gap: 10px;

                button {
                    @include normalButton();
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    svg {
                        font-size: 30px;
                    }

                    span {
                        margin-top: 3px;
                        font-size: 13px;
                    }


                }

                .green {
                    background-color: $green_color;
                }

                .description-button {
                    background-color: gray;
                }

                .cancel-button {
                    background-color: $red_color;
                }
            }
        }

    }




    @media screen and (max-width:550px) {
        .service-details-main-div {
            .page-border-div {

                .button-div {

                    button {
                        padding: 10px 3px;

                        svg {
                            font-size: 30px;
                        }

                        span {
                            margin-top: 3px;
                            font-size: 10px;
                        }
                    }
                }
            }

        }
    }
}