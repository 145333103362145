.single-tag-div {
    display: flex;
    gap: 15px;
    background-color: rgb(190, 190, 190);
    padding: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 5px;
    width: max-content;
    max-width: 400px;

    .text-div {

        pre {
            text-wrap: wrap;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .control-div {
        display: flex;
        gap: 5px;

        span {
            cursor: pointer;
        }
    }
}