.service-form-section-div {
    .work-category-list {
        display: flex;
        justify-content: space-evenly;
        margin-top: 15px;
        gap: 20px;
        flex-wrap: wrap;

        .item-div {
            width: 150px;
            background: rgb(202, 253, 254);
            background: linear-gradient(338deg, rgba(202, 253, 254, 0.8995973389355743) 0%, rgba(255, 243, 218, 0.8687850140056023) 47%, rgba(248, 249, 173, 0.8239670868347339) 100%);
            text-align: center;
            padding: 15px;
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border: 2px solid rgb(235, 235, 235);
            cursor: pointer;

            &:hover {
                border: 2px solid rgb(163, 163, 163);
            }

            .icon-div {
                background-color: rgba(255, 255, 255, 0.603);
                display: flex;
                width: fit-content;
                width: 75px;
                height: 75px;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                -ms-border-radius: 100%;
                -o-border-radius: 100%;
                color: rgb(8, 137, 139);

            }
        }
    }

    



}