@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.add-edit-sub-option-div {
    position: relative;
    

    .sub-options-div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 10px 0;

        .item-div {
            display: flex;
            gap: 5px;
            background-color: rgb(236, 236, 236);
            align-items: center;
            padding: 3px 5px;
            max-width: 100%;

            &:hover {
                background-color: rgb(207, 207, 207);
            }


            p {
                font-size: 12px;
                font-weight: 600;
                // @include threeDots();
                max-width: 92%;
                overflow: hidden;
            }

            svg {
                cursor: pointer;
            }

            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
        }


    }

    .add-div {
        display: grid;
        grid-template-columns: 5fr 1fr;
        align-items: center;
        gap: 10px;

        button {
            @include normalButton();
            @include iconInButton();
            font-size: 19px;
            background-color: $green_color;

        }
    }

    .button-div {
        button {
            @include normalButton();
            width: 100%;
            background-color: $primary_color;
        }
    }

    .loading-pop {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(240, 240, 240, 0.603);
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        backdrop-filter: blur(1px);
    }
}