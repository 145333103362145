@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.cancel-schedule-div {
    form {
        .button-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $red_color;
            }
        }
    }
}