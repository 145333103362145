@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.dar-list-div {
    .info-table {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 14px;
            font-weight: 600;
        }
    }


    tbody {

        td {
            .buttons {
                display: flex;
                justify-content: center;
                gap: 10px;

                .button-small-icon {
                    @include normalButton();
                    font-size: 16px;
                    padding: 7px 15px 5px 15px;
                    background-color: rgb(53, 53, 53);
                }

            }
        }
    }
}