@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.spare-listing-div {
    margin-top: 15px;
    padding-bottom: 20px;

    .section-one-div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }


    // Spare box
    .spare-box-div {
        background: rgb(202, 253, 254);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        text-align: center;
        min-width: 150px;
        max-width: 150px;
        padding: 8px 8px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
            border: 1px solid rgb(173, 173, 173);
        }

        .spare-id {
            font-size: 11px;
            font-weight: 600;
        }

        .spare_name {
            font-size: 13px;
            @include threeDots(2)
        }

        .price-div {
            margin-top: 7px;
            display: flex;
            gap: 5px;
            justify-content: center;

            p {
                font-size: 12px;
                font-weight: 600;
            }
        }

        .ssp-div {
            p {
                &:first-child {
                    text-decoration: line-through;
                    color: gray;
                }

                &:last-child {
                    color: black;
                }

            }

            span {
                font-size: 12px;
                font-weight: 600;
                color: rgb(25, 160, 104);
            }
        }



        .button-div {
            margin-top: 5px;

            button {
                @include normalButton();
                font-size: 12px;
                font-weight: 600;

            }

            .count-div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                button {
                    @include iconInButton();
                    font-size: 18px;
                    padding: 10px 10px;
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    -ms-border-radius: 100px;
                    -o-border-radius: 100px;

                    &:first-child {
                        background-color: $red_color;
                    }

                    &:last-child {
                        background-color: $green_color;
                    }
                }


                span {
                    font-size: 14px;
                    font-weight: 600;
                }

            }

            .add-div {
                display: flex;
                gap: 5px;

                button {
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    -ms-border-radius: 100px;
                    -o-border-radius: 100px;
                    background-color: $green_color;

                    &:first-child {
                        width: 100%;
                    }

                    &:nth-child(2) {
                        @include iconInButton();
                        font-size: 18px;
                        padding: 10px 10px;
                        background-color: $red_color;
                    }
                }
            }

            .delete-div {
                display: flex;
                gap: 5px;

                button {
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                    -ms-border-radius: 100px;
                    -o-border-radius: 100px;
                    background-color: rgb(66, 66, 66);
                    width: 100%;

                }
            }
        }

        .warranty-div {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 10px;
            font-weight: 600;
            background-color: rgba(3, 107, 177, 0.603);
            padding: 2px 8px;
            color: white;
            border-radius: 0px 0px 0px 8px;
            -webkit-border-radius: 0px 0px 0px 8px;
            -moz-border-radius: 0px 0px 0px 8px;
            -ms-border-radius: 0px 0px 0px 8px;
            -o-border-radius: 0px 0px 0px 8px;
        }

        .warranty-radio {
            position: absolute;
            left: 3px;
            top: 3px;
            font-size: 10px;
            font-weight: 600;
            background: none;
            padding: 2px 8px;
            color: white;
            border-radius: 0px 0px 0px 8px;
            -webkit-border-radius: 0px 0px 0px 8px;
            -moz-border-radius: 0px 0px 0px 8px;
            -ms-border-radius: 0px 0px 0px 8px;
            -o-border-radius: 0px 0px 0px 8px;
        }
    }

    .handle-buttons-div {
        background-color: white;
        position: fixed;
        padding: 10px 15px;
        width: 600px;
        max-width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
    }

    .more-spare {
        background: rgb(254, 233, 202);

        .warranty-div {
            background-color: rgba(0, 128, 107, 0.705);
        }

    }

    .more-div {
        justify-content: center;
        color: rgb(43, 43, 43);
        background-color: rgb(231, 231, 231);

        .icon-div {
            font-size: 35px;
        }

        p {
            font-size: 14px;
            font-weight: 600;
        }
    }
}