@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.timer-div {
    .timer-top {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
        padding: 30px 10px;

        span {
            font-size: 35px;
            font-weight: 700;
            font-family: 'Varela Round', sans-serif;
        }

        .milliseconds {
            font-size: 20px;
            color: rgb(100, 100, 100);
        }
    }

    .timer-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
            @include normalButton();
            padding: 10px 25px;
            font-size: 14px;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
        }

        .reset {
            background-color: rgb(82, 82, 82);
        }

        .start {
            background-color: $green_color;
        }

        .stop {
            background-color: $red_color;
        }

        .resume {
            background-color: $edit_color;
        }
    }
}