@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.token-top-up-div {
    form {

        .two-input-single-line-div {
            display: flex;
            gap: 0 10px;

            div {
                width: 100%;
            }
        }

        .amount-div {
            text-align: center;

            p {
                font-size: 13px;
                font-weight: 500;
            }

        }

        .button-div {
            margin-top: 10px;

            button {
                @include normalButton();
                width: 100%;
                background-color: $primary_color;
            }
        }
    }
}