@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';


.reg-list-div {

    .top-bar-div {
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        gap: 10px;
        padding: 5px;
        @include scrollBar();

        .item-div {
            display: flex;
            gap: 5px;
            align-items: center;
            background-color: rgb(235, 235, 235);
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            padding: 5px 10px;
            cursor: pointer;

            &:hover {
                background-color: rgb(196, 196, 196);
                color: black;

                span {
                    background-color: black;
                    color: white;
                }
            }


            p {
                font-size: 12px;
                font-weight: 600;
            }
        }

        .active {
            background-color: $edit_color;
            color: white;

            span {
                background-color: white;
                color: $edit_color;
            }
        }
    }

    .re-button {
        @include normalButton();
        background-color: gray;
        font-size: 13px;
        display: flex;

        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    table {
        tbody {
            td {
                .buttons-div {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    margin: 10px 0;

                    .button-small-icon {
                        @include normalButton();
                        font-size: 16px;
                        padding: 7px 15px 5px 15px;
                        background-color: rgb(53, 53, 53);
                    }

                    .add {
                        background-color: $green_color;
                    }

                    .edit {
                        background-color: $edit_color;
                    }

                    .delete {
                        background-color: $red_color;
                    }

                    .register {
                        background-color: rgb(255, 196, 0);
                        color: black
                    }

                    .postpone {
                        background-color: $red_color
                    }
                }
            }
        }
    }
}