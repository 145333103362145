@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.schedule-now-div {
    form {
        .button-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $primary_color;
            }
        }

        small {
            font-size: 12px;
            font-weight: 600;
            font-style: italic;
            color: rgb(205, 133, 50);
            text-align: center;
        }
    }
}