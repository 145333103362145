@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.expire-action-div {
    form {
        margin-top: 15px;

        .button-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $primary_color;
            }
        }
    }
}