@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';

.filter-service-list {
    .button-div {
        button {
            @include normalButton();
            width: 100%;
            margin-top: 10px;
            background-color: $primary_color;
        }
    }
}