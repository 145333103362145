@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';


.input-single-sub-option-list-div {



    .add-button {
        @include normalButton();
        background-color: $green_color;
        font-size: 13px;
        display: flex;

        svg {
            font-size: 18px;
            margin: 0;
            margin-right: 5px;
        }
    }

    .table-border-div {
        tbody {

            td {
                .buttons {
                    display: flex;
                    justify-content: center;
                    gap: 10px;

                    .button-small-icon {
                        @include normalButton();
                        font-size: 16px;
                        padding: 7px 15px 5px 15px;
                        background-color: rgb(53, 53, 53);
                    }

                    .edit {
                        background-color: $edit_color;
                    }

                    .delete {
                        background-color: $red_color;
                    }
                }
            }
        }
    }
}